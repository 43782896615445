<template>
  <div class="refundsuccess">
    <title-top>退款详情</title-top>
    <div class="column just-center font32 f-white bg-F62341 h176">
      <span class="ml25">退款成功</span>
      <span class="font24 ml25" style="display: inline-block">{{res.updated_at | formatDate}}</span>
    </div>
    <div class="cloumn bg-white mb16">
      <div class="row between align-center h96">
        <span class="ml25 font28 f-1A1A1A">退款总金额：</span>
        <div class="font32 f-EE7C3E mr25">
          ￥{{res.order.paid_amount}}
          <span class="font28"></span>
        </div>
      </div>
      <div class="line"></div>
      <div class="row between align-center h96">
        <span class="ml25 font28 f-1A1A1A">退回微信/支付宝/T宝</span>
        <div class="font32 mr25">
          ￥{{res.order.paid_amount}}
          <span class="font28"></span>
        </div>
      </div>
    </div>
    <div class="column bg-white font28 f-1A1A1A mb40">
      <span class="ml25 mt25">退款信息</span>
      <div class="row between align-center bg-white" v-for="(item,i) in res.order.items" :key="i">
        <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
        <div class="flex1 column font24 f-333333 mt25 mb25">
          <p class="mr25 ellipsis">{{item.product_name}}</p>
          <div class="row between f-999999 mt16 align-center">
            <span>{{item.specification_values | spec}}</span>
            <span class="ml25 mr25">×{{item.quantity}}</span>
          </div>
        </div>
      </div>
        <div class="line"></div>
        <div class="font24 f-999999 bg-white">
          <p class="mt25 ml25 mb16">退款原因：{{res.reason}}</p>
          <p class="ml25 mb16">退款金额：￥{{res.order.paid_amount}}</p>
          <p class="ml25 mb16">申请时间：{{res.created_at | formatDate}}</p>
          <p class="ml25 mb16">退款编号：{{res.number}}</p>
        </div>
      </div>
      <div class="row flex-center bg-white h96" @click="onSug">
        <img class="imgsize48" src="~images/zyimg/rebuy.png" alt />
        <span class="ml25 font28 f-666666">联系客服</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import { refundsDetail } from "network/igou2";
export default {
  name: "RefundSuccess",
  data() {
    return {
      res: {order:{items:[]}},
      id:this.$route.query.id
    };
  },
  mounted() {
    this._refundsDetail();
  },
  methods: {
    onSug() {
      this.$router.push("/sug");
    },
    _refundsDetail() {
      refundsDetail(this.id).then(res => {
        console.log(res);
        this.res = res.data;
      });
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    spec(specification_values) {
      let str = "";
      specification_values.forEach(item => {
        str += item.content + ",";
      });
      if (str) {
        str.substring(0, str.length - 1);
      }
      return str;
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.hd-border {
  width: 1.96rem;
  height: 0.76rem;
  border: 1px solid #999999;
}
</style>
